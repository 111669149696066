import { Account } from '@cliengo/types';

import { URLS } from '@/URLS';
import { axios } from '@/utils/axios';

const { API_URL } = URLS;

export async function getAccount() {
  const { data }: JsonObject = await axios.get(`${API_URL}/account`);

  return (Array.isArray(data) ? data[0] : data) as unknown as Account;
}
