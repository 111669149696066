import { configureStore } from '@reduxjs/toolkit';

import userData from './slices/userData.slice';
import planData from './slices/planData.slice';
import i18n from './slices/i18n.slice';

export const store = configureStore({
  reducer: {
    userData,
    planData,
    i18n,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
