const env = process.env.REACT_APP_ENV || 'development';

const URLS_SET = {
  sandbox: {
    LOGIN: 'https://www.login.stagecliengo.com',
    API_URL: 'http://convex.squad.devcliengo.com:9000/1.0',
    CORE_APP_URL: 'https://app.stagecliengo.com',
    WEBOPTIMIZER_URL: 'https://s.stagecliengo.com/weboptimizer',
    SOCKETEER: 'wss://stage-cliengo-socketeer.herokuapp.com/operators',
    LIVE: 'localhost:3030/live',
    CRM: 'https://crm.stagecliengo.com',
    LW: 'https://lw.stagecliengo.com',
    TRIGGERS: 'https://triggers.stagecliengo.com/triggers',
    PLANS: 'https://api.stagecliengo.com/1.0/plans?newPlans=true',
  },
  development: {
    LOGIN: 'https://www.login.stagecliengo.com',
    API_URL: 'https://api.stagecliengo.com/1.0',
    CORE_APP_URL: 'https://app.stagecliengo.com',
    WEBOPTIMIZER_URL: 'https://s.stagecliengo.com/weboptimizer',
    SOCKETEER: 'wss://stage-cliengo-socketeer.herokuapp.com/operators',
    LIVE: 'localhost:3030/live',
    CRM: 'https://crm.stagecliengo.com',
    LW: 'https://lw.stagecliengo.com',
    TRIGGERS: 'https://triggers.stagecliengo.com/triggers',
    PLANS: 'https://api.stagecliengo.com/1.0/plans?newPlans=true',
  },
  stage: {
    LOGIN: 'https://www.login.stagecliengo.com',
    API_URL: 'https://api.stagecliengo.com/1.0',
    CORE_APP_URL: 'https://app.stagecliengo.com',
    WEBOPTIMIZER_URL: 'https://s.stagecliengo.com/weboptimizer',
    SOCKETEER: 'wss://stage-cliengo-socketeer.herokuapp.com/operators',
    LIVE: 'https://live.stagecliengo.com/live',
    CRM: 'https://crm.stagecliengo.com',
    LW: 'https://lw.stagecliengo.com',
    TRIGGERS: 'https://triggers.stagecliengo.com/triggers',
    PLANS: 'https://api.stagecliengo.com/1.0/plans?newPlans=true',
  },
  production: {
    LOGIN: 'https://www.login.cliengo.com',
    API_URL: 'https://api.cliengo.com/1.0',
    CORE_APP_URL: 'https://app.cliengo.com',
    WEBOPTIMIZER_URL: 'https://s.cliengo.com/weboptimizer',
    SOCKETEER: 'wss://socketeer.cliengo.com/operators',
    LIVE: 'https://live.cliengo.com/live',
    CRM: 'https://crm.cliengo.com',
    LW: 'https://lw.cliengo.com',
    TRIGGERS: 'https://triggers.cliengo.com/triggers',
    PLANS: 'https://api.cliengo.com/1.0/plans?newPlans=true',
  },
};

type URLS_SET = typeof URLS_SET[keyof typeof URLS_SET];

export const URLS = URLS_SET[env as keyof typeof URLS_SET];
