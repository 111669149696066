import { accountAdapter, userAdapter } from '@/adapters';
import { getAccount } from '@/api/account';
import { getUser, getUsers } from '@/api/user';
import { getWebsites } from '@/api/websites';

export async function getUserData() {
  const user = userAdapter(await getUser());
  const users = await getUsers();
  const account = accountAdapter(await getAccount());
  const websites = await getWebsites();

  return { user, account, websites, users };
}
