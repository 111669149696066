import { createSlice } from '@reduxjs/toolkit';
import { User, Website } from '@cliengo/types';

import { AppAccount, AppUser } from '@/adapters';

export type UserDataState = {
  account: AppAccount;
  user: AppUser;
  users: User[];
  websites: Website[];
};

const initialState = {} as UserDataState;

export const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      Object.assign(state, action.payload);
    },
  },
});

export const userDataActions = userDataSlice.actions;

export default userDataSlice.reducer;
