import { User } from '@cliengo/types';
import { ApiResponse } from '@cliengo/types/types/global';

import { URLS } from '@/URLS';
import { axios } from '@/utils/axios';

const { API_URL } = URLS;

export async function getUser() {
  const { data }: JsonObject = await axios.get(`${API_URL}/users/me`);

  return (Array.isArray(data) ? data[0] : data) as unknown as User;
}

export async function getUsers() {
  const { data } = await axios.get<ApiResponse<User[]>>(`${API_URL}/users`);

  return (Array.isArray(data) ? data[0] : data.results) as unknown as User[];
}
