import { useEffect } from 'react';
import { Analytics } from 'cliengo-fe-utils';

import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { getUserData } from '@/services/getUserData';
import { userDataActions } from '@/store/slices/userData.slice';
import { objectIsEmpty } from '@/utils/objectIsEmpty';
import { i18nActions } from '@/store/slices/i18n.slice';
import { getTranslations } from '@/i18n';
import { planDataActions } from '@/store/slices/planData.slice';
import { getPlansData } from '@/services/getPlansData';

export const DataInitializer = () => {
  const dispatch = useAppDispatch();
  const { userData, i18n, planData } = useAppSelector((state) => state);

  useEffect(() => {
    if (objectIsEmpty(userData as unknown as JsonObject)) {
      getUserData()
        .then((userData) => {
          dispatch(userDataActions.setUserData(userData));
        })
        .catch(console.error);
    }
  }, [userData, dispatch]);

  useEffect(() => {
    if (
      objectIsEmpty(planData as unknown as JsonObject) &&
      !objectIsEmpty(userData as unknown as JsonObject)
    ) {
      getPlansData(userData)
        .then((planData) => {
          dispatch(planDataActions.setPlanData(planData));
        })
        .catch(console.error);
    }
  }, [planData, dispatch, userData]);

  useEffect(() => {
    if (
      objectIsEmpty(i18n) &&
      !objectIsEmpty(userData as unknown as JsonObject)
    ) {
      getTranslations(userData.user.lang)
        .then((translations) => {
          dispatch(i18nActions.setState(translations));
        })
        .catch(console.error);
    }
  }, [userData, i18n, dispatch]);

  useEffect(() => {
    if (!objectIsEmpty(userData as unknown as JsonObject)) {
      Analytics.initialize({
        segmentWriteKey: process.env.REACT_APP_SEGMENT_WRITE_KEY as string,
        sendAnalytics: 'YES',
        account: userData.account.raw as unknown as JsonObject,
        user: userData.user.raw as unknown as JsonObject,
      });
    }
  }, [userData]);

  return <></>;
};
