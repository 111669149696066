import { Suspense, lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

const MyPlan = lazy(() => import('@/views/MyPlan'));

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Suspense fallback={<p>...</p>}>
        <MyPlan />
      </Suspense>
    ),
  },
]);
