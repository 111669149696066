import { Website } from '@cliengo/types';

import { URLS } from '@/URLS';
import { axios } from '@/utils/axios';

export async function getWebsites() {
  const { data }: JsonObject = await axios.get(`${URLS.API_URL}/websites`);
  const { results } = (Array.isArray(data) ? data[0] : data) as JsonObject;

  return results as unknown as Website[];
}
