import { createSlice } from '@reduxjs/toolkit';

import { TranslationsShape } from '@/i18n';

export const i18nSlice = createSlice({
  name: 'i18n',
  initialState: {} as TranslationsShape,
  reducers: {
    setState: (state, action) => {
      Object.assign(state, action.payload);
    },
  },
});

export const i18nActions = i18nSlice.actions;

export default i18nSlice.reducer;
