import { AccountPlan } from '@cliengo/types';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {} as {
  plans: AccountPlan[];
  currentPlan: AccountPlan;
  recommendedPlan: string;
};

export const planDataSlice = createSlice({
  name: 'Plans',
  initialState,
  reducers: {
    setPlanData: (state, action) => {
      Object.assign(state, action.payload);
    },
  },
});

export const planDataActions = planDataSlice.actions;

export default planDataSlice.reducer;
