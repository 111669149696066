import { User } from '@cliengo/types';

export function userAdapter(user: User) {
  return {
    lang: user.language,
    raw: user,
  };
}

export type AppUser = ReturnType<typeof userAdapter>;
