import { AccountPlan } from '@cliengo/types';

import { URLS } from '@/URLS';
import { axios } from '@/utils/axios';

const { API_URL } = URLS;

export async function getPlan() {
  const { data }: JsonObject = await axios.get(`${API_URL}/account/plan`);

  return (Array.isArray(data) ? data[0] : data) as unknown as AccountPlan;
}

export async function getPlans(planType: string) {
  const { data }: JsonObject = await axios.get(
    `${API_URL}/plans?newPlans=true&includeTrial=true&planType=${planType}`
  );

  return ((Array.isArray(data) ? data[0] : data) as unknown as JsonObject)
    .results as unknown as AccountPlan[];
}
