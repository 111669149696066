import { AccountPlan } from '@cliengo/types';

import { getPlan, getPlans } from '@/api/plans';
import { getTierNumber } from '@/utils/getTierNumber';
import { UserDataState } from '@/store/slices/userData.slice';

const getPlansList = (plans: AccountPlan[], currentPlan: AccountPlan) => {
  const plansData = [] as AccountPlan[];

  plans.forEach((plan) => {
    const { isAnnualPlan, isPaidTier, isPlanFree } = utils;

    if (isPlanFree(currentPlan.tier) && getTierNumber(plan.tier) === 1) {
      return;
    }

    if (isPaidTier(currentPlan.tier) && !isPaidTier(plan.tier)) {
      return;
    }

    if (isAnnualPlan(currentPlan, plan)) {
      plansData.push(plan);
    }
  });

  return plansData;
};

export async function getPlansData(userData: UserDataState) {
  const { account } = userData;
  const currentPlan = await getPlan();
  const plans = await getPlans(account.planType);
  const plansData = getPlansList(plans, currentPlan);

  const myPlan = {
    ...currentPlan,
    futurePlan: String(currentPlan.futurePlan) || '',
    futurePlanChange: String(currentPlan.futurePlanChange) || '',
  };

  return {
    plans: plansData,
    currentPlan: myPlan,
  };
}

const utils = {
  isHigherTier: (currentTier: string, nextTier: string) => {
    return getTierNumber(currentTier) > getTierNumber(nextTier);
  },

  isLowerTier: (currentTier: string, nextTier: string) => {
    return getTierNumber(currentTier) < getTierNumber(nextTier);
  },

  isPaidTier: (tier: string) => {
    return getTierNumber(tier) > 1;
  },

  isPlanFree: (tier: string) => {
    return getTierNumber(tier) === 0;
  },

  isAnnualPlan: (currentPlan: AccountPlan, plan: AccountPlan) => {
    return currentPlan.isAnnualPlan === plan.isAnnualPlan;
  },

  getNextTier: (tier: string) => {
    return `TIER_${getTierNumber(tier) + 1}`;
  },
};

export const planDataUtils = utils;
