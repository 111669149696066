import { extendTheme } from '@chakra-ui/react';

const colors = {
  primary: {
    default: '#7D60F5',
    25: '#F4F1FF',
    50: '#DAD0FF',
    100: '#BCA8FD',
    200: '#A890FB',
    300: '#9378F8',
    400: '#7D60F5',
    500: '#673AB7',
    600: '#5E35B1',
    700: '#512DA8',
    800: '#4527A0',
    900: '#311B92',
  },
  secondary: {
    default: '#1ABC9C',
    25: '#EEFFFB',
    50: '#DCF3EE',
    100: '#AAE2D4',
    200: '#6FD0B8',
    300: '#1ABC9C',
    400: '#00AD87',
    500: '#009C74',
    600: '#008F68',
    700: '#007F58',
    800: '#006F4B',
    900: '#00522F',
  },
  danger: {
    default: '#DA2560',
    900: '#8A174F',
    800: '#AF1D57',
    700: '#C4225B',
    600: '#DA2560',
    500: '#EB2963',
    400: '#F33C6A',
    300: '#F95E7C',
    200: '#F97F8D',
    100: '#FAA1A8',
    50: '#FBCCC2',
    25: '#fff1f1',
  },
  warning: {
    default: '#F3AC1E',
    900: '#EF6D17',
    800: '#F18A1A',
    700: '#F29A1C',
    600: '#F3AC1E',
    500: '#F4B822',
    400: '#F5C52B',
    300: '#F6D03C',
    200: '#F7D74E',
    100: '#F8DE5F',
    50: '#FFEABF',
    25: '#fffcf1',
  },
  info: {
    default: '#3B86FF',
  },
  gray: {
    default: '#515151',
    50: 'fdfdfd',
    100: '#f8f8f8',
    200: '#f3f3f3',
    300: '#EAECF0',
    400: '#cdcdcd',
    500: '#b0b0b0',
    600: '#868686',
    700: '#707070',
    800: '#515151',
    900: '#444444',
  },
  white: '#FFFFFF',
  ligth: '#DEDEDE',
  dark: '#666666',
};

export const theme = extendTheme({
  colors,
  semanticTokens: {
    colors,
  },
  fonts: {
    heading: 'Nunito, sans-serif',
    body: 'Nunito, sans-serif',
  },
  styles: {
    global: {
      body: {
        color: colors.gray['800'],
        backgroundColor: colors.gray['200'],
      },
      h1: {
        fontSize: '48px',
        fontWeight: 'bold',
      },
      h2: {
        fontSize: '36px',
        fontWeight: 'bold',
      },
      h3: {
        fontSize: '30px',
        fontWeight: 600,
      },
      h4: {
        fontSize: '24px',
        fontWeight: 600,
      },
      h5: {
        fontSize: '20px',
        fontWeight: 600,
      },
      p: {
        fontSize: '16px',
      },
      button: {
        fontSize: '18px',
      },
      a: {
        color: colors.info.default,
        fontSize: '14px',
      },
    },
  },
});
