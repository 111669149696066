import es from './es';

export type TranslationsShape = typeof es;
export type LANG = 'es' | 'pt' | 'en';

export async function getTranslations(lang: LANG) {
  const translationFile = (await import(`./${lang}`)) as {
    default: TranslationsShape;
  };

  return translationFile.default;
}
