import { Account } from '@cliengo/types';

export function accountAdapter(account: Account) {
  return {
    lang: account.language,
    id: account.id,
    leadCount: account.leadCount,
    leadLimit: account.leadLimit,
    customUserLimit: account.customUserLimit,
    customWebsiteLimit: account.customWebsiteLimit,
    leadResetDate: account.leadResetDate,
    daysToLeadResetDate: account.daysToLeadResetDate,
    planCurrency: account.planCurrency,
    planPrice: account.planPrice,
    planName: account.planName,
    planType: account.planType,
    unitCountConversation: account.unitCountConversation,
    unitLimitConversation: account.unitLimitConversation,
    autoBuyLeadPackageEnabled: account.autoBuyLeadPackageEnabled,
    autoBuyConversationPackageEnabled:
      account.autoBuyConversationPackageEnabled,
    raw: account,
  };
}

export type AppAccount = ReturnType<typeof accountAdapter>;
